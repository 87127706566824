import React, { useRef, useEffect, useState } from 'react'
import Layout from '../components/layout'
import styles from './index.module.css'
import { Helmet } from 'react-helmet'

export default function Home() {
  const videoContainer = useRef()
  const [videoIndex, setVideoIndex] = useState(0)

  var videoSources = [
    'https://s3.amazonaws.com/flyingpoint/miami.mov',
    'https://s3.amazonaws.com/flyingpoint/nyc.mov',
  ];

  useEffect(() => {
    videoPlay(0);
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      var nextVid = videoIndex + 1;
      if (nextVid === videoSources.length) nextVid = 0;
      setVideoIndex(nextVid)
      videoPlay(nextVid);
    }, 9000);

    // cleanup setInterval
    return () => {
      clearInterval(timer);
    }
  }, [videoIndex]);

  const videoPlay = (videoNum) => {
    if (!videoContainer.current) return
    const vid = videoContainer.current.childNodes[videoNum]
    vid.pause()
    vid.currentTime = 0
    var isPlaying = vid.currentTime > 0 && !vid.paused && !vid.ended && vid.readyState > 2
    if (!isPlaying) vid.play()
  }

  return (
    <Layout page='home'>
      <Helmet>
        <title>Flying Point</title>
      </Helmet>
      <div className={styles.videoContainer} ref={videoContainer}>
        {videoSources.map((videoSource, i) => {
          return (
            <video style={{ opacity: i === videoIndex ? 1 : 0 }} preload='auto' muted>
              <source src={`${videoSource}#t=0.1`} type='video/mp4'></source>
            </video>
          )
        })}
      </div>
    </Layout>
  )
}
